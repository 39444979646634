const mod = {}
mod.core = () => {
  const menu = document.querySelector('.main-header__nav'), hamburger = document.querySelector('.hamburger'),
    header = document.querySelector('.main-header__main'), banner = document.querySelector('.main-header__top');
  let bannerHeight = banner.clientHeight;

  // Open Menu
  hamburger.addEventListener('click', () => {
    menu.classList.toggle('open');
    hamburger.classList.toggle('open');
    document.body.classList.toggle('overflow');
  });

  // Get banner height
  window.addEventListener('resize', () => {
    bannerHeight = banner.clientHeight;
  });

  // Sticky
  window.addEventListener('scroll', () => {
    if (window.scrollY > bannerHeight) {
      header.classList.add('sticky');
    } else {
      header.classList.remove('sticky');
    }
  });

  const del = (el) => {
    if (!el || !el.parentNode) return;
    el.parentNode.removeChild(el);
  };

  const each = (arr, fn) => {
    let i = 0, l = arr.length;
    for (i; i < l; i++) {
      fn(i, arr[i]);
    }
  };

  const render = (relEl, tpl, cfg = {}) => {
    if (!relEl) return;
    let child;

    if (tpl.nodeName === 'TEMPLATE') {
      child = document.importNode(tpl.content, true);
    } else if (typeof tpl === 'string') {
      const range = document.createRange();
      range.selectNode(relEl);
      child = range.createContextualFragment(tpl);
    } else {
      child = tpl;
    }

    relEl.appendChild(child);
    child = null;
    tpl = null;
    cfg = null;
    return relEl.lastElementChild;
  };

  let video_lang = document.body.querySelector('select[name="load_video"]'), tpl = {
    0: video_lang.nextElementSibling.firstChild
  }, video_nav = video_lang.previousElementSibling;

  video_nav && video_nav.addEventListener('click', (e) => {
    e.target.classList.toggle('open')
  })

  video_nav.children.length && each(video_nav.children, (key, val) => {
    val.addEventListener('click', (e) => {
      e.target.parentNode.classList.toggle('open')
      e.target.parentNode.dataset.name = val.textContent;
      video_lang.selectedIndex = key;
      renderVideo(video_lang);
    })
  })

  video_lang && video_lang.addEventListener('change', (e) => {
    renderVideo(e.target);
  })

  const renderVideo = (el) => {
    del(el.nextElementSibling.firstChild);
    !tpl[el.selectedIndex] ? render(el.nextElementSibling, `<iframe loading="lazy" src="${el.value}" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%" title="Introducing BAFL"></iframe>`) : el.nextElementSibling.appendChild(tpl[el.selectedIndex])
  }
  renderVideo(video_lang);
}

document.readyState === 'loading' ? document.addEventListener('DOMContentLoaded', mod.core) : mod.core();
